<template>
  <div>
    <h1 class="headline">标签权重</h1>
    <div class="banner">
      <!-- 使用搜索框组件 -->
      <InputView
        :CanYouClick="CanYouClick"
        placeholder-name="请输入店铺名"
        @sendSearchName="getSearchName"
      ></InputView>
      <!-- 讲解文案区域 -->
      <div style="padding: 0 200px 30px;font-size:20px;">
        <p>1.讲解使用方法.........................</p>
        <p>2.讲解使用的好处.........................</p>
        <p>3.备注一些输入的内容，比如只能搜索id的不能输入文字.........................</p>
      </div>
      <!-- 基本信息 -->
      <div style="padding: 0 50px 80px">
        <el-table :data="tableData" border style="width: 100%">
          <!-- 用户头像 -->
          <el-table-column label="用户头像" width="100" align="center">
            <template slot-scope="scope">
              <img
                style="width: 80px; height: 80px"
                :src="scope.row.avatar"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column label="基本信息" align="center">
            <!-- 分行显示 -->
            <template slot-scope="scope">
              <!-- 第一行 -->
              <el-table border :data="tableData">
                <el-table-column
                  label="是否为卖家"
                  width="100"
                  align="center"
                  >{{
                    scope.row.isTmall == 2
                      ? "天猫"
                      : scope.row.isTmall == 0
                      ? "非卖家"
                      : "C店"
                  }}</el-table-column
                >
                <el-table-column label="性别" align="center" width="100">{{
                  scope.row.sex
                }}</el-table-column>
                <el-table-column label="周平均单" align="center" width="100">{{
                  scope.row.weekOrder == "0"
                    ? scope.row.weekOrder2
                    : scope.row.weekOrder
                }}</el-table-column>
                <el-table-column label="月平均单" width="100" align="center"
                  >{{
                    scope.row.monthOrder == 0
                      ? scope.row.monthOrder2
                      : scope.row.monthOrder
                  }}
                </el-table-column>
                <el-table-column label="淘龄" width="100" align="center"
                  >{{ scope.row.taoAge }} 天</el-table-column
                >
                <el-table-column label="买家信誉值" width="100" align="center"
                  >{{
                    scope.row.bscore == 0 ? scope.row.bscore2 : scope.row.bscore
                  }}
                </el-table-column>

                <el-table-column label="买家单数" width="100" align="center">
                  {{ scope.row.not_good_ }}
                </el-table-column>
                <el-table-column label="买家等级" width="100" align="center">{{
                  scope.row.bLevel
                }}</el-table-column>
                <el-table-column label="买家信誉值等级" align="center">
                  <img :src="scope.row.bLevelIco" alt="" />
                </el-table-column>
                <el-table-column label="是否为超级会员" align="center">
                  {{ scope.row.vip_level == 0 ? "普通会员" : "超级会员" }}
                </el-table-column>
                <el-table-column
                  label="注册时间"
                  width="170"
                  prop="regTime"
                  align="center"
                >
                </el-table-column>
              </el-table>
              <!-- 第二行 -->
              <el-table border :data="tableData">
                <el-table-column label="卖家等级" width="100" align="center">{{
                  scope.row.sLevel
                }}</el-table-column>

                <el-table-column label="店铺好评率" width="100" align="center"
                  >{{ scope.row.sok_p }}
                </el-table-column>
                <el-table-column label="买家好评率" width="100" align="center">
                  {{ scope.row.bok_p }}
                </el-table-column>
                <el-table-column label="安全等级" width="100" align="center">{{
                  scope.row.safeType == 0
                    ? "安全"
                    : scope.row.safeType == 1
                    ? "一般"
                    : "危险"
                }}</el-table-column>

                <el-table-column label="VIP等级" width="100" align="center">{{
                  scope.row.vip
                }}</el-table-column>
                <el-table-column label="所在地区" width="100" align="center"
                  >{{ scope.row.area }}
                </el-table-column>

                <el-table-column
                  label="活跃度点数"
                  width="100"
                  align="center"
                  >{{ scope.row.active_point }}</el-table-column
                >

                <el-table-column label="活跃度等级" width="100" align="center">
                  {{ scope.row.active_level }}
                </el-table-column>

                <el-table-column label="卖家信誉值" align="center">
                  {{ scope.row.sscore }}
                </el-table-column>
                <el-table-column label="卖家家信誉值等级" align="center">
                  <img :src="scope.row.sLevelIco" alt="" />
                </el-table-column>
                <el-table-column label="认证情况" width="170" align="center">{{
                  scope.row.utype == 1 ? "未认证" : "支付宝实名认证"
                }}</el-table-column>
              </el-table>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 近期购买记录 -->
      <!-- <div class="purchaseHistory">
        <h3 style="text-align:center">近期购买记录</h3>
      </div> -->
    </div>
  </div>
</template>
<script>
import InputView from "../public/InputView";
import { checkWangwang } from "../../request/api"; //这里是引入请求
export default {
  data() {
    return {
      CanYouClick: true, //是否允许再次搜索
      // 基本信息data
      tableData: [
        {
          isTmall: 0, //是否为卖家(0，非卖家 | 1，C店 | 2，天猫)
          weekOrder2: "0", //周平均单2备用数值 小部分账号无法查到精准信誉值，对周月平均单有影响，建议加判断，如果bscore==0且bscore2!='0'时，取weekOrder2的值
          taoAge: 2639, //淘龄(淘宝年龄，单位为天)
          shop_ItemScore: "",
          bscore2: "0", //买家信誉值2备用数值  小部分账号无法查到信誉值，建议加判断，如果bscore==0且bscore2!='0'时，取bscore2的值
          sok_p: "99.47%", //店铺好评率
          utype: 2, //认证情况(1 未认证；2 支付宝实名认证)
          sLevel: 9, //卖家等级
          shop_ServiceScore: "",
          shop_Sid: "",
          type: "",
          bLevelIco: "http://img.alicdn.com/newrank/", //买家信誉值等级图片地址
          sLevelIco: "https://img.alicdn.com/newrank/s_blue_4.gif", //卖家家信誉值等级图片地址
          not_good_: 0, //已停用，目前显示为买家单数
          bscore: 0, //买家信誉值

          safeType: 2, //安全等级(0 安全；1 一般 ；2 危险 【 此内容仅供参考】)
          Created: "",
          last_visit: "未知", //最后登录时间 该字段暂时失效，
          shop_UsedCount: "",
          weekOrder: 0, //周平均单(买入单数的周平均值)
          bLevel: 0, //买家等级
          vip: "-2", //VIP等级
          shop_ShopScore: "",
          has_shop: 1, //是否为卖家，目前已停用，该值已与isSeller整合
          shop_Desc: "",
          area: "未知", //所在地区
          shop_PicPath: "",
          monthOrder: 0, //月平均单(买入单数的月平均值)
          active_point: 35030, //活跃度点数
          peopletagCat: "",
          tagCat: "",
          isSeller: 1,
          peopletags: "",
          sex: "男号", //性别(直接中文显示：男号/女号/未知)
          shop_Created: "",
          //用户头像(图片地址)
          avatar:
            "https://wsapi.jianghu.taobao.com/wangwang/headshow.htm?_input_charset=utf-8&longId=cntaobao102kg",
          shop_DeliveryScore: "",
          monthOrder2: "0", //月平均单2备用数值 小部分账号无法查到精准信誉值，对周月平均单有影响，建议加判断，如果bscore==0且bscore2!='0'时，取monthOrder2的值
          regTime: "2013-12-03 23:42:36", //注册时间 部分账号无法查询
          bok_p: "100%", //买家好评率
          s_time: "2021-02-24 11:11:53",
          shop_Title: "",
          active_level: 34, //活跃度等级
          vip_level: 0, //是否为超级会员(0=普通会员， 10=超级会员)
          username: "102kg", //username
          sscore: 2603, //卖家信誉值
        },
      ],
      // 近期购买记录data
      purchaseHistoryData: [],
    };
  },
  components: {
    InputView,
  },
  methods: {
    //   子传父的方法
    getSearchName(value) {
      this.$store.commit("alterLoadContent", "正在获取标签权重的相关数据");
      this.CanYouClick = false; //不允许再点击搜索
      checkWangwang({ account: value }).then((data) => {
        //发送请求
        this.tableData = [data.data.accountInfo];
      }).finally(() => {
        this.$store.commit("alterJumpFlag", false);
        this.CanYouClick = true; //允许再次搜索
      });
    },
  },
};
</script>
 
<style lang = "less" scoped>
.purchaseHistory {
  margin: 20px 50px 100px;
  padding: 10px;
  border: 1px solid #eeeeee;
}
</style>